<template>
  <div
    ref="filterContainerRef"
    class="relative flex items-center justify-between px-2 -mt-1 bg-white rounded-t"
    style="box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.07); z-index: 50; height: 55px;"
  >
    <div class="flex gap-2 item-center">
      <button
        class="filter-base-dropdown-button app-form-reset"
        :class="{
          'bg-gray-100 text-gray-700 border-gray-200': !isShown,
          'bg-white text-oDark border-gray-200': isShown,
        }"
        aria-label="Filter menu"
        aria-haspopup="true"
        @click="onClickFilterMenu"
      >
        <i class="fas fa-filter text-oCharcoal" />

        <span class="font-semibold">Filters</span>

        <span
          :class="`filter-pills-count`"
          :style="
            `visibility: ${
              computeFilterablesAppliedCount > 0 ? 'visible' : 'hidden'
            }`
          "
        >
          {{ computeFilterablesAppliedCount }}
        </span>
      </button>

      <!-- <TDropdown variant="filterPill">
          <template
            #trigger="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
                isShown,
              }"
          >
            <button
              class="filter-base-dropdown-button app-form-reset"
              :class="{
                'bg-gray-100 text-gray-700 border-gray-200': !isShown,
                'bg-white text-oDark border-gray-200 shadow-md': isShown,
                'filter-is-applied': filterFleet.isApplied,
              }"
              aria-label="Filter menu"
              aria-haspopup="true"
              @mousedown="mousedownHandler"
              @focus="focusHandler"
              @blur="blurHandler"
              @keydown="keydownHandler"
            >
              <span class="text-sm font-bold">Fleet</span>

              <i class="fas fa-chevron-down" />
            </button>
          </template>

          <template v-slot:default="{ hide, blurHandler }">
            <div class="px-6 py-4 border-b">
              <TCheckbox
                :model="filterFleet.all"
                :label="`All`"
                :name="`Fleets`"
                :variant="`liveMapTitle`"
                :class="`ml-1`"
                wrapped
                @click="onFilterGroupSelectAllToggle({ group: 'filterFleet' })"
              />

              <div class="h-1" />

              <div
                v-for="fleet in fleetsData"
                :key="`fleet-filter-${fleet.id}`"
                class="filter-item"
              >
                <TCheckbox
                  :model="filterFleet.models[fleet.id]"
                  :label="`${fleet.name}`"
                  :name="fleet.id"
                  :variant="`liveMap`"
                  wrapped
                  @change="
                    $store.commit('liveMap/onFilterChange', {
                      group: 'filterFleet',
                      key: fleet.id,
                      value: $event,
                    })
                  "
                />

                <div class="filter-item-count">
                  {{ getFleetVehicleCount[fleet.id] }}
                </div>
              </div>
            </div>

            <div class="flex justify-end gap-4 p-2 mt-4">
              <AppButton
                :text="`Clear`"
                :variant="`secondary`"
                :height="`32px`"
                @click="
                  hide()
                  onFilterGroupClear({ group: 'filterFleet' })
                "
                @blur="blurHandler"
              />

              <AppButton
                :text="`Show ${getFilterFleetCount} vehicles`"
                :variant="`green`"
                :height="`32px`"
                :disabled="shouldBeDisabled({ group: 'filterFleet' })"
                @click="
                  hide()
                  onFilterGroupApply({ group: 'filterFleet' })
                "
                @blur="blurHandler"
              />
            </div>
          </template>
        </TDropdown> -->

      <!-- NOTE: loop through the `computeVisiblePills` for responsive UI,
        but always bind with the real `filters` object to reference state -->
      <TDropdown
        v-for="filter in computeVisiblePills"
        :key="filter.key"
        variant="filterPill"
      >
        <template
          #trigger="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
                isShown,
              }"
        >
          <button
            class="filter-base-dropdown-button app-form-reset"
            :class="{
              'bg-gray-100 text-gray-700 border-gray-200': !isShown,
              'bg-white text-oDark border-gray-200 shadow-md': isShown,
              'filter-is-applied': filter.isApplied,
            }"
            aria-label="Filter menu"
            aria-haspopup="true"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
          >
            <span class="text-sm font-bold">{{ getFiltersTitle(filter) }}</span>
            <span
              v-if="getFiltersTitleCount(filter) > 0"
              :class="`selected-filter-pills-count`"
            >
              {{ getFiltersTitleCount(filter) }}
            </span>
            <i class="fas fa-chevron-down" />
          </button>
        </template>

        <template v-slot:default="{ hide, blurHandler }">
          <div class="px-6 py-4 border-b">
            <!-- NOTE: use `filters` instead of `computeVisibleFilters`
              to reference the real state in model bindings-->
            <TCheckbox
              :model="filters[filter.key].all"
              :label="`All`"
              :name="`Vehicle Status`"
              :variant="`liveMapTitle`"
              :class="`ml-1`"
              wrapped
              @click="onFilterGroupSelectAllToggle({ group: filter.key })"
            />

            <div class="h-1" />

            <!-- NOTE: use `filters` instead of `computeVisibleFilters`
              to reference the real state in model bindings-->
            <div class="overflow-auto max-h-60 SB-FarhanShares">
              <div
                v-for="(modelValue, modelKey) in filters[filter.key].models"
                :key="filter.key + modelKey"
                :class="`filter-item`"
              >
                <TCheckbox
                  :model="modelValue"
                  :label="filter.labels[modelKey]"
                  :name="filter.labels[modelKey]"
                  :variant="`liveMap`"
                  wrapped
                  @change="
                    $store.commit('liveMap/onFilterChange', {
                      group: filter.key,
                      key: modelKey,
                      value: $event,
                    })
                  "
                />

                <div class="filter-item-count">
                  {{
                    getFilterCounts[filter.key] &&
                    getFilterCounts[filter.key][modelKey] &&
                    getFilterCounts[filter.key][modelKey].count
                      ? getFilterCounts[filter.key][modelKey].count
                      : '0'
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-end gap-4 p-2 mt-4">
            <AppButton
              :text="`Clear`"
              :variant="`secondary`"
              :height="`32px`"
              @click="
                hide()
                onFilterGroupClear({ group: filter.key })
              "
              @blur="blurHandler"
            />

            <AppButton
              :text="`Show ${getFilterGroupCounts[filter.key].count} vehicles`"
              :variant="`green`"
              :height="`32px`"
              :disabled="shouldBeDisabled({ group: filter.key })"
              @click="
                hide()
                onFilterGroupApply({ group: filter.key })
              "
              @blur="blurHandler"
            />
          </div>
        </template>
      </TDropdown>

      <!-- filter-pills-last-item -->
      <TDropdown
        v-if="computeConcealedPillsCount > 0"
        variant="filterPill"
        class="hidden sm:block"
      >
        <template
          #trigger="{
                  mousedownHandler,
                  focusHandler,
                  blurHandler,
                  keydownHandler,
                  isShown,
                }"
        >
          <button
            class="flex items-center justify-center px-1 py-1 text-sm text-black border border-gray-200 rounded-full cursor-pointer app-form-reset hover:bg-white"
            style="height:35px; width:35px"
            :class="{
              'bg-gray-100 text-gray-700 border-gray-200': !isShown,
              'bg-white text-oDark border-gray-200 shadow-md': isShown,
            }"
            aria-label="Filter pill menu"
            aria-haspopup="true"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
          >
            {{ computeConcealedPillsCount }}+
          </button>
        </template>

        <div class="flex flex-col gap-2 px-2 py-2">
          <!-- NOTE: loop through the `computeVisiblePills` for responsive UI,
            but always bind with the real `filters` object to reference state -->
          <TDropdown
            v-for="filter in computeConcealedPills"
            :key="filter.key"
            variant="filterPill"
          >
            <template
              #trigger="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
                isShown,
              }"
            >
              <button
                class="filter-base-dropdown-button app-form-reset"
                :class="{
                  'bg-gray-100 text-gray-700 border-gray-200': !isShown,
                  'bg-white text-oDark border-gray-200 shadow-md': isShown,
                  'filter-is-applied': filter.isApplied,
                }"
                aria-label="Filter menu"
                aria-haspopup="true"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
              >
                <span class="text-sm font-bold">{{ filter.title }}</span>

                <i class="fas fa-chevron-down" />
              </button>
            </template>

            <template v-slot:default="{ hide, blurHandler }">
              <div class="px-6 py-4 border-b">
                <!-- NOTE: use `filters` instead of `computeVisibleFilters`
              to reference the real state in model bindings-->
                <TCheckbox
                  :model="filters[filter.key].all"
                  :label="`All`"
                  :name="`Vehicle Status`"
                  :variant="`liveMapTitle`"
                  :class="`ml-1`"
                  wrapped
                  @click="onFilterGroupSelectAllToggle({ group: filter.key })"
                />

                <div class="h-1" />

                <!-- NOTE: use `filters` instead of `computeVisibleFilters`
              to reference the real state in model bindings-->
                <div class="overflow-auto max-h-60 SB-FarhanShares">
                  <div
                    v-for="(modelValue, modelKey) in filters[filter.key].models"
                    :key="filter.key + modelKey"
                    :class="`filter-item`"
                  >
                    <TCheckbox
                      :model="modelValue"
                      :label="filter.labels[modelKey]"
                      :name="filter.labels[modelKey]"
                      :variant="`liveMap`"
                      wrapped
                      @change="
                        $store.commit('liveMap/onFilterChange', {
                          group: filter.key,
                          key: modelKey,
                          value: $event,
                        })
                      "
                    />

                    <div class="filter-item-count">
                      {{ getFilterCounts[filter.key][modelKey].count }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex justify-end gap-4 p-2 mt-4">
                <AppButton
                  :text="`Clear`"
                  :variant="`secondary`"
                  :height="`32px`"
                  @click="
                    hide()
                    onFilterGroupClear({ group: filter.key })
                  "
                  @blur="blurHandler"
                />

                <AppButton
                  :text="
                    `Show ${getFilterGroupCounts[filter.key].count} vehicles`
                  "
                  :variant="`green`"
                  :height="`32px`"
                  :disabled="shouldBeDisabled({ group: filter.key })"
                  @click="
                    hide()
                    onFilterGroupApply({ group: filter.key })
                  "
                  @blur="blurHandler"
                />
              </div>
            </template>
          </TDropdown>
        </div>
      </TDropdown>
    </div>

    <div
      class="flex items-center gap-2 px-4 py-1 border rounded-full shadow-sm bg-gray-50"
    >
      <span class="text-14px">Realtime</span>
      <TToggle
        variant="success"
        v-model="isRealtime"
        @change="onChangeRealtime"
      />
      <span class="w-5 font-bold text-14px">
        {{ isRealtime ? 'On' : 'Off' }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { EventBus, resolveProp } from '@/utils'
export default {
  name: 'LiveMapFilterPills',

  components: {
    // FSTableFilterGroup: () =>
    //   import('@/components/fs-table-filter/FSTableFilterGroup'),
  },

  data() {
    return {
      isShown: false,
      fleetVehiclesCount: {},
      filterContainerWidth: 0,

      isRealtime: false, // todo: move to state
    }
  },

  computed: {
    ...mapState({
      reqBusy: (state) => state.liveMap.req.busy,
      reqInit: (state) => state.liveMap.req.init,

      filters: (state) => state.liveMap.filters,

      filterFleet: (state) => state.liveMap.filters.filterFleet,
      filterVehicleType: (state) => state.liveMap.filters.filterVehicleType,
      filterConnectivity: (state) => state.liveMap.filters.filterConnectivity,
      filterBatteryStatus: (state) => state.liveMap.filters.filterBatteryStatus,
      filterLockStatus: (state) => state.liveMap.filters.filterLockStatus,
      filterVehicleMode: (state) => state.liveMap.filters.filterVehicleMode,
      filterVehicleStatus: (state) => state.liveMap.filters.filterVehicleStatus,
      // vehicles: (state) => state.liveMap.data,
    }),

    ...mapGetters({
      getResData: 'liveMap/getResData',
      // resMeta: 'liveMap/getResMeta',
      fleetsData: 'liveMap/getFleetsData',
      getFilteredData: 'liveMap/getFilteredData',
      getFilterFleetSelected: 'liveMap/getFilterFleetSelected',
      getFilteredFleetVehicles: 'liveMap/getFilteredFleetVehicles',

      summary: 'liveMap/getFilteredFleetSummary',
      getFleetVehicleCount: 'liveMap/getFleetVehicleCount',
    }),

    getFilterSlotMaxWidth() {
      const leftSide = 140 // all filter button
      const rightSide = 170 // realtime toggle button
      const width = this.filterContainerWidth - rightSide - leftSide

      return width > 0 ? width : 0
    },

    computeVisiblePillsMaxCount() {
      // 45px allowance for the last element
      const avialableWidth = this.getFilterSlotMaxWidth - 45
      // based on use case / an wild guess (I can't help more on it as of now)
      const averageWidthPerPill = 125
      // least possible value for making sure UI won't be broken
      return Math.floor(avialableWidth / averageWidthPerPill)
    },

    computeVisiblePills() {
      let n = 1
      let pills = {}

      for (const k in this.filters) {
        if (n > this.computeVisiblePillsMaxCount) {
          break
        }
        pills[k] = this.filters[k]
        n++
      }

      // console.log('visiblePills', Object.keys(pills).length)

      return pills
    },

    computeConcealedPills() {
      let n = 1
      const concealedPills = {}

      for (const k in this.filters) {
        if (n <= this.computeVisiblePillsMaxCount) {
          n++
          continue
        }
        concealedPills[k] = this.filters[k]
      }
      return concealedPills
    },

    computeConcealedPillsCount() {
      return Object.keys(this.computeConcealedPills).length
    },

    getFilteredDataCount() {
      return Object.keys(this.getFilteredData).length
    },

    computeFilterablesAppliedCount() {
      let count = 0
      if (this.filterFleet.isApplied) count += 1
      if (this.filterVehicleType.isApplied) count += 1
      if (this.filterConnectivity.isApplied) count += 1
      if (this.filterBatteryStatus.isApplied) count += 1
      if (this.filterLockStatus.isApplied) count += 1
      if (this.filterVehicleMode.isApplied) count += 1
      if (this.filterVehicleStatus.isApplied) count += 1
      // loop through all filter groups -> look for true values, add count
      return count
    },

    getFilterCounts() {
      // ds: key:{count}
      const filterFleet = {}
      for (const k in this.getFleetVehicleCount) {
        filterFleet[k] = { count: this.getFleetVehicleCount[k] }
      }

      // ds: key:{count, percentage}
      const c = {
        filterFleet,

        filterVehicleType: {
          bike: { count: this.summary.type.bike },
          eBike: { count: this.summary.type.eBike },
          scooter: { count: this.summary.type.scooter },
        },

        // todo: return count from the getter
        filterConnectivity: {
          operational: { count: this.summary.connectivity.operational },
          nonOperational: { count: this.summary.connectivity.nonOperational },
        },

        filterLockStatus: {
          locked: { count: this.summary.iot.locked },
          unlocked: { count: this.summary.iot.unlocked },
        },

        filterBatteryStatus: {
          low: this.summary.summary.onLowBattery,
          medium: this.summary.summary.onMediumBattery,
          high: this.summary.summary.onHighBattery,
        },

        // count available
        filterVehicleMode: {
          available: this.summary.status.available,
          unavailable: this.summary.status.unavailable,
          rebalance: this.summary.status.rebalance,
          maintenance: this.summary.status.maintenance,
          swapBattery: this.summary.status.swapBattery,
        },

        // count - available
        filterVehicleStatus: {
          onTrip: this.summary.summary.onTrip,
          onReservation: this.summary.summary.onReservation,
          onParking: this.summary.summary.onParking,
          onIllegallyParked: this.summary.summary.onIllegallyParked,
          onCharging: this.summary.summary.onCharging,
          onGeoFenceAlert: this.summary.summary.onGeoFenceAlert,
        },
      }

      // console.log({ c })
      return c
    },

    getFilterFleetCount() {
      // console.log({ t: this.getFilterCounts, x: this.getFilterFleetSelected })
      let count = 0

      for (const fleetId in this.getFilterFleetSelected) {
        const c = this.getFilterCounts?.filterFleet[fleetId]?.count
        // console.log({ fleetId, c })
        if (typeof c === 'number') {
          count += c
        }
      }

      // console.log('cf', count)
      return count
    },

    getFilterVehicleTypeCount() {
      const counts = this.getFilterCounts.filterVehicleType
      const { bike, eBike, scooter } = this.filterVehicleType.models

      let count = 0

      bike && (count += counts.bike.count)
      eBike && (count += counts.eBike.count)
      scooter && (count += counts.scooter.count)

      return count
    },

    getFilterConnectivityCount() {
      const counts = this.getFilterCounts.filterConnectivity
      const { operational, nonOperational } = this.filterConnectivity.models

      let count = 0

      operational && (count += counts.operational.count)
      nonOperational && (count += counts.nonOperational.count)

      return count
    },

    getFilterLockStatusCount() {
      const counts = this.getFilterCounts.filterLockStatus
      const { locked, unlocked } = this.filterLockStatus.models

      if (locked && unlocked) return counts.locked.count + counts.unlocked.count
      else if (locked) return counts.locked.count
      else if (unlocked) return counts.unlocked.count
      else return 0
    },

    getFilterVehicleModeCount() {
      const counts = this.getFilterCounts.filterVehicleMode
      const {
        available,
        unavailable,
        rebalance,
        maintenance,
        swapBattery,
      } = this.filterVehicleMode.models

      let count = 0

      available && (count += counts.available.count)
      unavailable && (count += counts.unavailable.count)
      rebalance && (count += counts.rebalance.count)
      maintenance && (count += counts.maintenance.count)
      swapBattery && (count += counts.swapBattery.count)

      return count
    },

    getFilterBatteryStatusCount() {
      const counts = this.getFilterCounts.filterBatteryStatus
      const { low, medium, high } = this.filterBatteryStatus.models

      let count = 0

      low && (count += counts.low.count)
      medium && (count += counts.medium.count)
      high && (count += counts.high.count)
      return count
    },

    getFilterVehicleStatusCount() {
      let count = 0

      const c = this.summary.summary
      const m = this.filterVehicleStatus.models

      for (const k in m) {
        m[k] && (count += c[k].count)
      }
      return count
    },

    getFilterGroupCounts() {
      return {
        filterFleet: { count: this.getFilterFleetCount },

        filterVehicleType: { count: this.getFilterVehicleTypeCount },

        filterConnectivity: { count: this.getFilterConnectivityCount },

        filterLockStatus: { count: this.getFilterLockStatusCount },

        filterBatteryStatus: { count: this.getFilterBatteryStatusCount },

        filterVehicleMode: { count: this.getFilterVehicleModeCount },

        filterVehicleStatus: { count: this.getFilterVehicleStatusCount },
      }
    },
  },

  created() {
    window.removeEventListener('resize', this.getFilterContainerWidth)
    window.addEventListener('resize', this.getFilterContainerWidth)
    this.getFilterContainerWidth()
  },

  methods: {
    getFiltersTitle(filter) {
      if (filter.isApplied) {
        const selectedFilters = Object.keys(filter.models).filter(
          (modelKey) => filter.models[modelKey] === true
          // console.log('filtersPill-modelKey', modelKey)
        )
        if (selectedFilters.length > 1) {
          return filter.title
        } else {
          const selectedKey = Object.keys(filter.labels).find(
            (labelKey) => labelKey === selectedFilters[0]
          )
          console.log('selectedKey', selectedKey)
          return filter.labels[selectedKey]
        }
      } else {
        return filter.title
      }
    },
    getFiltersTitleCount(filter) {
      if (filter.isApplied) {
        const selectedFilters = Object.keys(filter.models).filter(
          (modelKey) => filter.models[modelKey] === true
        )
        return selectedFilters.length
      } else {
        return 0
      }
    },
    onClickFilterMenu() {
      const currentState = !this.isShown
      this.$emit('filter-menu', { shown: currentState })
      this.isShown = currentState
      this.$store.commit('liveMap/drawerRightVisibilityToggle')
    },

    isFilterGroupApplied() {
      // loop through the group, if any of one element is true (except all), return true, exit early
      return Date.now / 2 === 0
    },

    // eslint-disable-next-line no-unused-vars
    getFilterGroupItemCount({ group, key, value, operator }) {
      let vehicles

      if (group === 'filterFleet') {
        // use all vehicles to count fleet filter vehicles
        vehicles = { ...this.getResData }
        // console.log('fffff')
      } else {
        // fleet filtered vehicles are the source of other filter
        vehicles = { ...this.getFilteredFleetVehicles }
      }

      // console.log(vehicles)

      let count = 0
      for (const k in vehicles) {
        const prop = resolveProp(key, vehicles[k])
        if (operator === 'min') {
          const v = Number(prop).toFixed()
          if (v >= value) {
            count += 1
          }
        } else if (operator === 'minmax') {
          const v = Number(prop).toFixed()
          const [min, max] = value
          if (v >= min && v <= max) {
            count += 1
          }
        } // defaults to equal operator
        else if (prop == value) {
          count += 1
        }
      }

      return count
    },

    // eslint-disable-next-line no-unused-vars
    onFilterGroupApply({ group }) {
      // append or push or update the filtered result to the list view & map marker
      this.$store.dispatch('liveMap/onFilterApply', { group, isApplied: true })
    },

    onFilterGroupClear({ group, isApplied = false }) {
      // set all models as false
      this.$store.dispatch('liveMap/onFilterClear', {
        group,
        isApplied,
      })

      // apply the filter
      // this.$store.dispatch('liveMap/onFilterApply', { group, isApplied })
    },

    onFilterGroupSelectAllToggle({ group }) {
      // todo: group validation -> on err -> console.log -> through exception
      this.$store.commit('liveMap/onFilterChange', {
        group,
        key: 'all',
      })
      console.log('selectAll')
    },

    onChangeRealtime(open) {
      EventBus.$emit('livemap-realtime-toggle-status', open)
      if (open) {
        this.$store.dispatch('liveMap/openWSConnections')
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Live Tracking On',
            text: 'Live Realtime tracking mode is turned on',
          },
          3000
        )
      } else {
        this.$store.dispatch('liveMap/closeWSConnections')
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Live Tracking Off',
            text: 'Live Realtime tracking mode is turned off',
          },
          3000
        )
      }
    },

    shouldBeDisabled({ group }) {
      // todo: validate filter group
      const count = this.getFilterGroupCounts[group]?.count || 0

      console.log({ shouldBeDisabled: count })
      return count > 0 ? false : true
    },

    getFilterContainerWidth() {
      this.$nextTick(() => {
        this.filterContainerWidth =
          this.$refs?.filterContainerRef?.clientWidth || 0
        console.log({ tlsw: this.filterContainerWidth })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-item {
  @apply flex items-center justify-between;

  .filter-item-count {
    @apply text-xs text-gray-500 mb-1;
  }
}
</style>

<style lang="scss" scoped>
.filterGroupsContainer {
  min-width: 241px;
}
@media screen and (min-width: 640px) {
  .filterGroupsContainer {
    width: auto;
  }
}
.filter-pills-count {
  height: 17px;
  width: 17px;
  font-size: 13px;
  line-height: 17px;
  @apply inline-block text-center text-white bg-blue-700 rounded-full;
}

.filter-base-dropdown-button {
  @apply flex items-center gap-3 px-4 py-1.5 border rounded-full hover:bg-white text-14px;
}

.min-w-filter-pill {
  min-width: 12rem;
  max-width: 15rem;
}

.fs-table-filter-dropdown-menu {
  min-width: 181px;
  width: auto;
  max-width: calc(100vw - 50px);
}

@media screen and (min-width: 640px) {
  .fs-table-filter-dropdown-menu {
    min-width: 330px;
    width: 50vw;
  }
}
.filter-is-applied {
  color: #fff !important;
  background-color: rgba(59, 130, 246, 1) !important;
}
.selected-filter-pills-count {
  height: 17px;
  width: 17px;
  font-size: 13px;
  line-height: 17px;
  @apply inline-block text-center text-blue-900 bg-gray-200 rounded-full font-bold;
}
</style>
