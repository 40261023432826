<script>
// import Fuse from 'fuse.js'
import { getVehicleEnabledFlags, getVehicleFlagAlias } from '@/utils'
import { mapState, mapGetters } from 'vuex'

import VueTagsInput from '@johmun/vue-tags-input'
import './$tag-input-cusotm.scss'

import BatteryStatus from '@/components/badge/BatteryStatus.vue'
import HeartbeatStatus from '@/components/badge/HeartbeatStatus.vue'
import AskDown from '@/components/dropdown/AskDown.vue'
import AppButton from '@/components/form/AppButton.vue'
import { getAskDownOptions } from '@/store/modules/live-map/live-map.helpers'
import { openRWS, closeRWS } from '@/services'

import VehicleFlagIcon from '@/components/badge/VehicleFlagIcon'
import { getUTCAwareTime } from '@/utils'
import { mqMixin } from '@/plugins/media-query'

export default {
  name: 'LDrawerList',

  mixins: [mqMixin],

  components: {
    BatteryStatus,
    HeartbeatStatus,
    AskDown,
    VueTagsInput,
    AppButton,
    VehicleFlagIcon,
  },

  props: {
    reqInit: {
      type: Boolean,
      default: true,
      required: false,
    },

    reqBusy: {
      type: Boolean,
      default: true,
      required: false,
    },
  },

  data() {
    return {
      tag: '',
      tags: [],
      tagsMax: 5,
      tagsPlaceholder: '+ Add Tag',
      tagsAutocompletion: [
        {
          text: 'New',
        },
        {
          text: 'Broken',
        },
        {
          text: 'Repaired',
        },
        {
          text: 'Should Repair',
        },
        {
          text: 'On Test Ride',
        },
      ],

      // bulk action panel state
      isExpanded: false,
      isProcessing: false,
      // on-zero -> dissmiss button shown && socket closed
      // on-null -> dismiss button hidden
      bulkActionTimer: null,
      bulkVehicleStatus: '',
      bulkHardwareAction: '',
      vehicleModeOptions: [
        {
          title: this.$t(
            'components.livemapManagement.choices.vehicleMode.available'
          ),
          text: this.$t(
            'components.livemapManagement.choices.vehicleMode.available'
          ),
          value: 'AV',
          shapeColor: 'green',
        },
        {
          title: this.$t(
            'components.livemapManagement.choices.vehicleMode.unavailable'
          ),
          text: this.$t(
            'components.livemapManagement.choices.vehicleMode.unavailable'
          ),
          value: 'UV',
          shapeColor: '#D1D5DB',
        },
        {
          title: this.$t(
            'components.livemapManagement.choices.vehicleMode.rebalance'
          ),
          text: this.$t(
            'components.livemapManagement.choices.vehicleMode.rebalance'
          ),
          value: 'RB',
          shapeColor: 'blue',
        },
        {
          title: this.$t(
            'components.livemapManagement.choices.vehicleMode.maintenance'
          ),
          text: this.$t(
            'components.livemapManagement.choices.vehicleMode.maintenance'
          ),
          value: 'MT',
          shapeColor: 'purple',
        },
        {
          title: this.$t(
            'components.livemapManagement.choices.vehicleMode.swapBattery'
          ),
          text: this.$t(
            'components.livemapManagement.choices.vehicleMode.swapBattery'
          ),
          value: 'SB',
          shapeColor: 'orange',
        },
      ],
      hardwareActionOption: [
        {
          title: this.$t(
            'components.livemapManagement.choices.hardwareAction.lock'
          ),
          text: this.$t(
            'components.livemapManagement.choices.hardwareAction.lock'
          ),
          value: 'lock',
          shapeColor: 'white',
        },
        {
          title: this.$t(
            'components.livemapManagement.choices.hardwareAction.unlock'
          ),
          text: this.$t(
            'components.livemapManagement.choices.hardwareAction.unlock'
          ),
          value: 'unlock',
          shapeColor: 'white',
        },
        {
          title: this.$t(
            'components.livemapManagement.choices.hardwareAction.headlightOn'
          ),
          text: this.$t(
            'components.livemapManagement.choices.hardwareAction.headlightOn'
          ),
          value: 'headlightOn',
          shapeColor: 'white',
        },
        {
          title: this.$t(
            'components.livemapManagement.choices.hardwareAction.headlightOff'
          ),
          text: this.$t(
            'components.livemapManagement.choices.hardwareAction.headlightOff'
          ),
          value: 'headlightOff',
          shapeColor: 'white',
        },
        {
          title: this.$t(
            'components.livemapManagement.choices.hardwareAction.taillightOn'
          ),
          text: this.$t(
            'components.livemapManagement.choices.hardwareAction.taillightOn'
          ),
          value: 'taillightOn',
          shapeColor: 'white',
        },
        {
          title: this.$t(
            'components.livemapManagement.choices.hardwareAction.taillightOff'
          ),
          text: this.$t(
            'components.livemapManagement.choices.hardwareAction.taillightOff'
          ),
          value: 'taillightOff',
          shapeColor: 'white',
        },
        {
          title: this.$t(
            'components.livemapManagement.choices.hardwareAction.throttleOn'
          ),
          text: this.$t(
            'components.livemapManagement.choices.hardwareAction.throttleOn'
          ),
          value: 'throttleOn',
          shapeColor: 'white',
        },
        {
          title: this.$t(
            'components.livemapManagement.choices.hardwareAction.throttleOff'
          ),
          text: this.$t(
            'components.livemapManagement.choices.hardwareAction.throttleOff'
          ),
          value: 'throttleOff',
          shapeColor: 'white',
        },
        {
          title: this.$t(
            'components.livemapManagement.choices.hardwareAction.triggerRing'
          ),
          text: this.$t(
            'components.livemapManagement.choices.hardwareAction.triggerRing'
          ),
          value: 'triggerRing',
          shapeColor: 'white',
        },
      ],
    }
  },

  computed: {
    filteredItems() {
      return this.tagsAutocompletion.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
      })
    },

    ...mapState({
      drawerVisibility: (s) => s.liveMap.drawerVisibility,
      drawerBottomVisibility: (s) => s.liveMap.drawerBottomVisibility,

      // todo: rename hwA to something better
      hwA: (state) => state.liveMap.hwA,
      processingCount: (state) => state.liveMap.hwA.processing,
      hwALogs: (state) => state.liveMap.hwALogs,
      // bulk action state

      filterLastAppliedAt: (state) => state.liveMap.filterLastAppliedAt,

      filteredSortBy: (state) => state.liveMap.filteredSortBy,
      filteredSortByActive: (state) => state.liveMap.filteredSortByActive,

      listChunksVisible: (state) => state.liveMap.listChunksVisible,

      refetching: (state) => state.liveMap.req.refetching,
    }),

    ...mapGetters({
      getVehicleList: 'liveMap/getVehicleList',
      getVehicleListCount: 'liveMap/getVehicleListCount',

      getVehicleListChunks: 'liveMap/getVehicleListChunks',

      getVehicleListSearched: 'liveMap/getVehicleListSearched',

      getVehicleListSelected: 'liveMap/getVehicleListSelected',
      getVehicleListSelectedCount: 'liveMap/getVehicleListSelectedCount',
    }),

    getVehicles() {
      if (this.filteredSearch) return this.getVehicleListSearched

      return this.listChunksVisible
    },

    listSelectAll: {
      get() {
        return this.$store.state.liveMap.listSelectAll
      },

      set(value) {
        this.$store.commit('liveMap/onSelectAll', { value })
      },
    },

    filteredSearch: {
      get() {
        return this.$store.state.liveMap.filteredSearch
      },

      set(v) {
        this.$store.commit('liveMap/onFilteredSearch', v)
      },
    },

    getProcessingStyle() {
      const { total, failed, succeeded } = this.hwA
      const progress = failed + succeeded
      let percentage = (progress * 100) / total

      // NaN handler (and/or always have slight {1%} visual )
      if (typeof percentage !== 'number' || percentage === 0) {
        percentage = 1
      }
      // console.log({ percentage, total, failed, succeeded, hs: this.hwA })

      return `width: ${percentage}%; max-width: 100%;`
    },
  },

  created() {
    // non-reactive reconnecting-web-socket instance
    this.rws = null
    // non-reactive fuse.js instance
    this.fuse = null
  },

  methods: {
    getAskDownOptions,
    getVehicleEnabledFlags,
    getVehicleFlagAlias,
    getUTCAwareTime,
    getTimesPassed(focusDate) {
      const currentTime = new Date()

      const focusTime = new Date(focusDate)
      const diffTime = currentTime - focusTime

      return this.milisecToDHMSConverter(diffTime ? diffTime : 0)
    },
    milisecToDHMSConverter(ms) {
      const years = Math.floor(ms / (365 * 24 * 60 * 60 * 1000))
      const yearsms = ms % (365 * 24 * 60 * 60 * 1000)

      const months = Math.floor(yearsms / (30 * 24 * 60 * 60 * 1000))
      const monthsms = ms % (30 * 24 * 60 * 60 * 1000)

      const days = Math.floor(monthsms / (24 * 60 * 60 * 1000))
      const daysms = ms % (24 * 60 * 60 * 1000)

      const hours = Math.floor(daysms / (60 * 60 * 1000))
      const hoursms = ms % (60 * 60 * 1000)

      const minutes = Math.floor(hoursms / (60 * 1000))
      const minutesms = ms % (60 * 1000)

      const seconds = Math.floor(minutesms / 1000)

      if (years > 0) {
        return `${years}y ${months}mo ago`
      }
      if (months > 0 && years <= 0) {
        return `${months}mo ${days}d ago`
      }
      if (days > 0 && months <= 0 && years <= 0) {
        return `${days}d ${hours}h ago`
      }
      if (hours > 0 && days <= 0 && months <= 0 && years <= 0) {
        return `${hours}h ${minutes}m ago`
      }
      if (minutes > 0 && hours <= 0 && days <= 0 && months <= 0 && years <= 0) {
        return `${minutes}m ${seconds}s ago`
      }
      if (
        seconds > 0 &&
        minutes <= 0 &&
        hours <= 0 &&
        days <= 0 &&
        months <= 0 &&
        years <= 0
      ) {
        return `${seconds}s ago`
      }
      if (
        seconds <= 0 &&
        minutes <= 0 &&
        hours <= 0 &&
        days <= 0 &&
        months <= 0 &&
        years <= 0
      ) {
        return '--'
      }
    },

    // data -> vehicle
    onNavigate(to, data) {
      const from = this.$options.name
      this.$emit(`navigate`, { to, from, data })
    },

    // getTimeAgo(time) {
    //   //offset in hours

    //   // const tz = this.$dayjs.tz.guess()
    //   if (!this.$dayjs(time).isValid()) {
    //     return '--'
    //   }

    //   const clientTimezoneOffset = (new Date().getTimezoneOffset() / 60) * -1
    //   return this.$dayjs(time)
    //     .add(clientTimezoneOffset, 'hour')
    //     .fromNow()
    // },

    lazyLoadVehicles(isVisible) {
      isVisible &&
        this.$store.dispatch('liveMap/lazyLoadVehicles', { reset: false })
    },

    async onRefetch() {
      if (this.refetching) {
        return
      }

      await this.$store.dispatch('liveMap/refetchVehicles')
    },

    onFilteredSort({ type }) {
      const state = this.filteredSortBy[type]
      console.log({ state })
      this.$store.commit('liveMap/onFilteredSort', { active: type })
    },

    getFilteredSortOrder({ type }) {
      const state = this.filteredSortBy[type]
      return state === 'asc'
        ? this.$t('components.livemapManagement.list.item.ascending')
        : state === 'desc'
        ? this.$t('components.livemapManagement.list.item.descending')
        : this.$t('components.livemapManagement.list.item.notApplied')
    },

    getFilteredSortIcon({ type }) {
      const state = this.filteredSortBy[type]
      return state === 'asc'
        ? 'fa-arrow-down text-green-500'
        : state === 'desc'
        ? 'fa-arrow-up text-green-500'
        : 'fa-minus-circle'
    },

    /**
     * Bulk Actions
     */

    async onStartBulkAction() {
      // todo: add validation before dispatching / commiting
      // todo: use store -> automate it
      this.isProcessing = true

      await openRWS({
        channel: 'bikeUpdates',
        onMessage: async ({ data }) => {
          // console.log({ data })
          const msg = await JSON.parse(data)
          // console.log({ msg })
          const d = msg.data
          const e = msg.error

          if (this.getVehicleListSelected[d.id]) {
            if (this.hwA.processing > 0) {
              if (d) {
                this.$store.commit('liveMap/addHwASucceeded', {
                  vehicle: { id: d.id, qr_code: d.qr_code },
                  logs: d,
                })
              } else if (e) {
                this.$store.commit('liveMap/addHwAFailed', {
                  vehicle: { id: msg.bike_id },
                  logs: e,
                })
              }
            }
          }
        },
      })
        .then(async (rws) => {
          this.bulkActionTimer = 60

          // add a non-reactive instance (can be used to close the connection from outer scope)
          this.rws = rws
          console.log({ rws, thisRws: this.rws })

          // await this.$store.dispatch('liveMap/fakeSocket')
          const vehicles = this.getVehicleListSelected

          if (this.bulkVehicleStatus && this.bulkHardwareAction) {
            await this.$store.dispatch('liveMap/setVehicleMode', {
              vehicles,
              mode: this.bulkVehicleStatus,
              isDetect: false,
            })
            await this.$store.dispatch('liveMap/hardwareAction', {
              vehicles,
              action: this.bulkHardwareAction,
            })
          } else {
            if (this.bulkVehicleStatus) {
              await this.$store.dispatch('liveMap/setVehicleMode', {
                vehicles,
                mode: this.bulkVehicleStatus,
                isDetect: true,
              })
            }
            if (this.bulkHardwareAction) {
              await this.$store.dispatch('liveMap/hardwareAction', {
                vehicles,
                action: this.bulkHardwareAction,
              })
            }
          }

          let countdown = setInterval(async () => {
            console.log(this.bulkActionTimer)
            if (this.bulkActionTimer === 0 || this.processingCount <= 0) {
              clearInterval(countdown)

              await this.onFinishBulkAction()

              // sync bulkActionTimer -> the second logic (processingCount < 0) can end the process earlier
              this.bulkActionTimer = 0
              return
            }
            this.bulkActionTimer -= 1
          }, 1000)
        })
        .catch((e) => {
          console.log({ rwsErr: e })
          // R&D: null || 0
          this.bulkActionTimer = null
        })
    },

    async onFinishBulkAction() {
      this.$store.commit('liveMap/forceFailed')

      const { total, succeeded, failed, processing } = this.hwA
      const text = `Total:  ${total} | Succeeded: ${succeeded} | Failed: ${failed} | Timed-out: ${processing}`

      this.$forceUpdate()

      // console.log({ b4close: this.rws })
      // console.log('closing sock conn...')
      await closeRWS(this.rws).then(() => {
        this.rws = null
      })
      console.log({ a2close: this.rws })
      // this.rws.close('4043', 'closed')
      // console.log({ fClose2: this.rws })

      // panel state
      // this.isProcessing = false
      // this.isExpanded = false

      this.$notify(
        {
          group: 'bottomRight',
          type: 'danger',
          title: 'Bulk Action Summary',
          text,
        },
        10000
      )
    },

    async onDismiss() {
      // todo: sync state of vehicles
      this.bulkActionTimer = null
      this.isProcessing = false
      this.isExpanded = false
      await this.$store.dispatch('liveMap/refetchVehicles')
      this.$store.commit('liveMap/syncHwA', {
        total: 0,
        succeeded: 0,
        failed: 0,
        processing: 0,
        resetLogs: true,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ba-shadow {
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.25);
}
.bulk-actions-width {
  // bottom drawer is full width & shown on small screens
  width: 100%;

  @media screen and (min-width: 640px) {
    // left drawer has a toggle button
    // keeping space for left drawer toggle button
    width: calc(100% - 2.5rem);
  }
}

.bulk-actions-height {
  // the panel is 80vh
  min-height: 400px;
}

.bulk-actions-height-full {
  // the panel is 80vh
  min-height: 78vh !important;
}

.x-label {
  display: block;
  cursor: pointer;
  // margin: 20px 0 0;
  // border-bottom: 1px solid green;
}
.x-label:hover:hover {
  // text-decoration: underline;
}

// .x-label  .x-input[type='checkbox']:checked  {
// }

.x-input {
  // position: absolute;
  visibility: hidden;
  // left: -999em;
}
.x-input[type='checkbox']:checked + .x-hide {
  // opacity: 0;
  // max-height: 0;
  display: none;
  // border: none;
}

.x-hide {
  display: block;
  margin-top: 8px;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  // width: 50%;
  color: #000;
  // border: 1px solid #000;
  background: #9d9d9d;
  // max-height: 99em;
  // opacity: 1;
  height: auto;
  overflow: hidden;
  // transition: opacity 0.6s ease-in-out, max-height 0.1s ease-in-out;
}
</style>

<style lang="scss" scoped>
.sort-by-button {
  width: 100px;
  @apply py-3 px-2 text-xs text-center text-gray-500 hover:bg-gray-100;
}
// .traiangle-up {
//   height: 0;
//   width: 0;
//   border-left: 6px solid transparent;
//   border-right: 6px solid transparent;
//   border-bottom: 12px solid #ddd;
// }
.-right-2 {
  right: -0.5rem;
}
.traiangle-up {
  $bg: #fff;
  $border: rgba(229, 231, 235, 1);

  /* Styling block element, not required */
  position: relative;
  margin-top: 2em;
  // padding: 1em;
  border-top: 1px solid $border;
  background: $bg;

  /* Required for Down Triangle */
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-top: 0;
  }

  /* Stroke */
  &:before {
    top: -16px;
    // right: 21px;
    right: 7px;

    /* If 1px darken stroke slightly */
    border-bottom-color: darken($border, $amount: 3%);
    border-width: 16px;
  }

  /* Fill */
  &:after {
    top: -15px;
    // right: 22px;
    right: 8px;
    border-bottom-color: $bg;
    border-width: 15px;
  }
}
</style>

<template>
  <div class="">
    <div>
      <div class="flex items-center px-5 mt-1 sm:mt-4">
        <button
          class="py-1.5 px-3.5 app-form-reset hover:bg-gray-100 flex items-center app-form-reset"
          @click="onNavigate('LDrawerSummary')"
        >
          <i class="text-xs fa fa-arrow-left" />
        </button>

        <span class="ml-1 font-semibold text-19px">
          <!-- {{ data.list.vehicles.length || 0 }} Vehicles -->
          {{ getVehicleListCount }}
          {{ $t('components.livemapManagement.list.headline.vehicles') }}
        </span>
      </div>

      <!-- search-area -->
      <div class="px-5 pt-1">
        <input
          v-model="filteredSearch"
          type="search"
          class="mt-4 search-area"
          :placeholder="
            $t('components.livemapManagement.globalSearch.placeholder')
          "
        />
      </div>
      <!-- {{ filteredSearch }} -->
      <!-- /search-area -->

      <div class="py-3 px-5 text-14px">
        <div class="flex justify-between items-center mt-3 mb-5">
          <div class="flex items-center">
            <TCheckbox
              v-model="listSelectAll"
              :label="
                listSelectAll
                  ? $t('components.livemapManagement.list.item.deselectAll')
                  : $t('components.livemapManagement.list.item.selectAll')
              "
              :name="`selectAllFromList`"
              :variant="`lg`"
              wrapped
            />
          </div>

          <div class="flex gap-3 items-center mr-2">
            <svg
              class="w-5 h-5 text-gray-500 cursor-pointer"
              :class="{ 'animate-spin': refetching }"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              @click="onRefetch"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              ></path>
            </svg>

            <TDropdown
              :classes="{
                dropdown: 'origin-top-right -right-2 w-64',
              }"
            >
              <template
                #trigger="{
            mousedownHandler,
            focusHandler,
            blurHandler,
            keydownHandler,
          }"
              >
                <button
                  class="app-form-reset"
                  aria-label="Sort Options"
                  aria-haspopup="true"
                  @mousedown="mousedownHandler"
                  @focus="focusHandler"
                  @blur="blurHandler"
                  @keydown="keydownHandler"
                >
                  <svg
                    class="w-5 h-5"
                    :class="
                      filteredSortByActive ? 'text-green-500' : 'text-gray-500'
                    "
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h7"
                    ></path>
                  </svg>
                </button>
              </template>

              <!-- sorting -->
              <template #default>
                <div class="traiangle-up" />

                <div
                  class="py-3 px-4 pt-4 bg-white rounded-none border border-t-0 border-gray-200"
                >
                  <span class="font-bold text-14px">{{
                    $t('components.livemapManagement.list.item.sortBy')
                  }}</span>

                  <div class="flex justify-between items-center mt-4">
                    <span class="text-xs">{{
                      $t('components.livemapManagement.list.item.qrCode')
                    }}</span>

                    <button
                      class="sort-by-button app-form-reset"
                      @click="onFilteredSort({ type: 'qrCode' })"
                    >
                      <span v-text="getFilteredSortOrder({ type: 'qrCode' })" />
                      <i
                        class="ml-1 text-xs text-gray-500 fas"
                        :class="getFilteredSortIcon({ type: 'qrCode' })"
                      />
                    </button>
                  </div>

                  <div class="flex justify-between items-center">
                    <span class="text-xs">{{
                      $t('components.livemapManagement.list.item.batteryStatus')
                    }}</span>

                    <button
                      class="sort-by-button app-form-reset"
                      @click="onFilteredSort({ type: 'battery' })"
                    >
                      <span
                        v-text="getFilteredSortOrder({ type: 'battery' })"
                      />
                      <i
                        class="ml-1 text-xs text-gray-500 fas"
                        :class="getFilteredSortIcon({ type: 'battery' })"
                      />
                    </button>
                  </div>

                  <div class="flex justify-between items-center">
                    <span class="text-xs">{{
                      $t('components.livemapManagement.list.item.lastMoved')
                    }}</span>

                    <button
                      class="sort-by-button app-form-reset"
                      @click="onFilteredSort({ type: 'lastMoved' })"
                    >
                      <span
                        v-text="getFilteredSortOrder({ type: 'lastMoved' })"
                      />
                      <i
                        class="ml-1 text-xs text-gray-500 fas"
                        :class="getFilteredSortIcon({ type: 'lastMoved' })"
                      />
                    </button>
                  </div>
                </div>
              </template>
              <!-- /sorting -->
            </TDropdown>
          </div>
        </div>

        <!-- vehicle-list -->
        <template v-if="filteredSearch.length > 2">
          <div
            v-for="vehicle in getVehicleListSearched"
            :key="vehicle.id"
            :class="`grid grid-cols-9 gap-y-4 text-xs border-b`"
          >
            <div class="flex flex-col col-span-3 justify-center h-20">
              <div class="flex items-center">
                <TCheckbox
                  v-model="vehicle.isSelected"
                  :label="$truncate(vehicle.name, 14, null, '..')"
                  :variant="`liveMap`"
                  :title="vehicle.name"
                  wrapped
                  @change="
                    $store.commit('liveMap/onSelect', {
                      key: vehicle.id,
                      value: $event,
                    })
                  "
                />

                <!-- <TCheckbox
                        v-model="vehicle.isSelected"
                        @change="
                          $store.commit('liveMap/onSelect', {
                            key: vehicle.id,
                            value: $event,
                          })
                        "
                      />
                      <span class="ml-2">{{ vehicle.qr_code }}</span> -->
              </div>

              <div
                class="ml-8 -mt-1 text-xs text-gray-500"
                :title="vehicle.name"
              >
                {{ vehicle.qr_code }}
                <!-- {{ vehicleIndex }} {{ vehicleLista.length - 1 }} -->
              </div>
            </div>

            <div class="flex flex-col col-span-3 justify-center h-20">
              <div class="flex items-center">
                <HeartbeatStatus
                  :status="vehicle.lock.is_operational"
                  :text-class="`ml-2 text-xs`"
                  :is-available="vehicle.is_available"
                />
              </div>

              <div
                class="mt-1.5 text-gray-500"
                :title="
                  `Last Heartbeat At: ` +
                    getUTCAwareTime(vehicle.last_heartbeat_time)
                "
              >
                {{ getTimesPassed(vehicle.last_heartbeat_time) }}
              </div>
            </div>

            <div class="flex flex-col col-span-2 justify-center h-20">
              <div class="flex items-center">
                <BatteryStatus
                  :level="vehicle.lock.power_level"
                  :height="`18px`"
                  :width="`35px`"
                  :text-class="`text-xs text-gray-600 ml-2`"
                />
              </div>

              <div class="mt-1.5 flex items-center">
                <!-- todo: add control flags -->
                <template
                  v-for="(flag, flagIndex) in getVehicleEnabledFlags({
                    general: vehicle.general_flags,
                    operational: vehicle.operational_flags,
                    status: vehicle.status_flags,
                    locked: { is_locked: vehicle.lock.is_locked },
                  })"
                >
                  <vehicle-flag-icon
                    v-tooltip.bottom="getVehicleFlagAlias(flag)"
                    size="xs"
                    class="mr-1"
                    :name="flag"
                    :key="`vehicle-${vehicle.id}-flag-${flagIndex}`"
                  />
                </template>
              </div>
            </div>

            <div class="flex justify-center items-center h-20">
              <button
                class="py-1.5 px-3.5 app-form-reset hover:bg-gray-100"
                @click="onNavigate('LDrawerDetails', vehicle)"
              >
                <i class="text-xs text-gray-600 fas fa-chevron-right" />
              </button>
            </div>
          </div>
        </template>

        <template v-else>
          <!-- chunks: {{ listChunksVisible.length }} -->

          <section
            v-for="(chunk, chunkIndex) in getVehicleListChunks"
            :key="`ds-${chunkIndex}`"
          >
            <!-- group count: {{ chunk.length }} -->
            <div
              v-for="vehicle in chunk"
              :key="vehicle.id"
              :class="`grid grid-cols-9 gap-y-4 text-xs border-b`"
            >
              <div class="flex flex-col col-span-3 justify-center h-20">
                <div class="flex items-center">
                  <TCheckbox
                    v-model="vehicle.isSelected"
                    :label="$truncate(vehicle.name, 14, null, '..')"
                    :variant="`liveMap`"
                    :title="vehicle.name"
                    wrapped
                    @change="
                      $store.commit('liveMap/onSelect', {
                        key: vehicle.id,
                        value: $event,
                      })
                    "
                  />

                  <!-- <TCheckbox
                        v-model="vehicle.isSelected"
                        @change="
                          $store.commit('liveMap/onSelect', {
                            key: vehicle.id,
                            value: $event,
                          })
                        "
                      />
                      <span class="ml-2">{{ vehicle.qr_code }}</span> -->
                </div>

                <div
                  class="ml-8 -mt-1 text-xs text-gray-500"
                  :title="vehicle.name"
                >
                  {{ vehicle.qr_code }}
                  <!-- {{ vehicleIndex }} {{ vehicleLista.length - 1 }} -->
                </div>
              </div>

              <div class="flex flex-col col-span-2 justify-center h-20">
                <div class="flex items-center">
                  <HeartbeatStatus
                    :status="vehicle.lock.is_operational"
                    :text-class="`ml-2 text-xs`"
                    :is-available="vehicle.is_available"
                  />
                </div>

                <div
                  class="mt-1.5 text-gray-500"
                  :title="
                    `Last Heartbeat At: ` +
                      getUTCAwareTime(vehicle.last_heartbeat_time)
                  "
                >
                  {{ getTimesPassed(vehicle.last_heartbeat_time) }}
                </div>
              </div>

              <div class="flex flex-col col-span-3 justify-center h-20 pl-2">
                <div class="flex items-center">
                  <BatteryStatus
                    :level="vehicle.lock.power_level"
                    :height="`18px`"
                    :width="`35px`"
                    :text-class="`text-xs text-gray-600 ml-2`"
                  />
                </div>

                <div class="mt-1.5 flex items-center">
                  <!-- todo: add control flags -->
                  <template
                    v-for="(flag, flagIndex) in getVehicleEnabledFlags({
                      general: vehicle.general_flags,
                      operational: vehicle.operational_flags,
                      status: vehicle.status_flags,
                      locked: { is_locked: vehicle.lock.is_locked },
                      unlocked: { is_unlocked: !vehicle.lock.is_locked },
                    })"
                  >
                    <vehicle-flag-icon
                      v-tooltip.bottom="getVehicleFlagAlias(flag)"
                      size="xs"
                      class="mr-1"
                      :name="flag"
                      :key="`vehicle-${vehicle.id}-flag-${flagIndex}`"
                    />
                  </template>
                </div>
              </div>

              <div class="flex justify-center items-center h-20">
                <button
                  class="py-1.5 px-3.5 app-form-reset hover:bg-gray-100"
                  @click="onNavigate('LDrawerDetails', vehicle)"
                >
                  <i class="text-xs text-gray-600 fas fa-chevron-right" />
                </button>
              </div>
            </div>

            <div
              v-observe-visibility="{
                callback: lazyLoadVehicles,
                once: true,
              }"
            />
          </section>
        </template>
        <!-- /vehicle-list -->
      </div>
    </div>

    <!-- blur -->
    <div
      v-if="isExpanded || isProcessing"
      class="absolute top-0 left-0 h-full bg-gray-500 opacity-50 bulk-actions-width"
      @click="onDismiss"
    />

    <!-- space for bulk-actions -->
    <div class="h-10" />

    <!-- bulk-actions -->
    <section
      class="absolute left-0 z-10 bg-white transition-all duration-300 ease-in-out bulk-actions-width ba-shadow"
      :class="
        `${isProcessing ? 'bulk-actions-height-full' : 'bulk-actions-height'} ${
          !drawerVisibility || (isSmDown && !drawerBottomVisibility)
            ? 'invisible'
            : 'visible'
        }`
      "
      :style="
        `${
          isExpanded ? 'bottom: 0px;' : 'bottom: calc((400px - 2.5rem) * -1);'
        }`
      "
    >
      <!-- panel-header -->
      <div
        class="flex justify-between items-center px-4 h-10 border-b border-gray-200"
      >
        <div class="text-lg font-medium">
          {{ $t('components.livemapManagement.list.bulk.title') }}
          <span :title="`Total Selected: ${getVehicleListSelectedCount}`"
            >({{ getVehicleListSelectedCount }})</span
          >
        </div>

        <div class="flex gap-3 items-center">
          <button
            v-if="bulkActionTimer === 0"
            class="flex justify-center items-center py-1 px-4 text-xs text-gray-700 bg-gray-100 hover:text-red-500 hover:bg-red-50 app-form-reset"
            @click="onDismiss"
          >
            {{ $t('components.livemapManagement.list.bulk.dismiss') }}
          </button>
          <span v-if="isProcessing && bulkActionTimer > 0"
            >{{ bulkActionTimer }} s</span
          >

          <button
            class="flex justify-center items-center py-1 px-4 bg-gray-100 hover:bg-gray-50 app-form-reset"
            @click="isExpanded = !isExpanded"
          >
            <i
              class="text-gray-600 fas"
              :class="isExpanded ? 'fa-minus' : 'fa-chevron-up'"
            />
          </button>
        </div>
      </div>
      <!-- /panel-header -->

      <div class="pb-5">
        <!-- actions -->
        <template v-if="!isProcessing">
          <!-- vehicle-status -->
          <div class="flex justify-between items-center p-3">
            <span class="text-sm font-bold"
              >{{ $t('components.livemapManagement.list.bulk.vehicleStatus') }}
            </span>
            <AskDown
              v-model="bulkVehicleStatus"
              :options="vehicleModeOptions"
              shape-enabled
            />
          </div>
          <!-- /vehicle-status -->

          <!-- hardware-action -->
          <div class="flex justify-between items-center p-3 mt-3">
            <span class="text-sm font-bold">{{
              $t('components.livemapManagement.list.bulk.hardwareAction')
            }}</span>
            <AskDown
              v-model="bulkHardwareAction"
              :value="'active'"
              :options="hardwareActionOption"
            />
          </div>
          <!-- {{ bulkHardwareAction }} -->
          <!-- /hardware-action -->

          <!-- tags -->
          <section class="py-3 px-3 border-t border-b">
            <div class="flex gap-3 items-center">
              <i class="text-sm fas fa-tags"></i>
              <span class="font-bold text-14px">{{
                $t('components.livemapManagement.list.bulk.tags')
              }}</span>
            </div>

            <div class="mt-2 taggables">
              <VueTagsInput
                v-model="tag"
                :tags="tags"
                :autocomplete-items="filteredItems"
                :max-tags="tagsMax"
                :max-length="15"
                :placeholder="tagsPlaceholder"
                @tags-changed="(newTags) => (tags = newTags)"
              />
            </div>
          </section>
          <!-- /tags -->

          <!-- submission -->
          <div class="flex justify-between items-center px-3 mt-10">
            <div class="text-14px">
              {{ getVehicleListSelectedCount }}
              {{
                $t('components.livemapManagement.list.bulk.vehiclesSelected')
              }}
            </div>

            <AppButton
              :text="$t('components.stepNavigation.apply')"
              @click="onStartBulkAction"
            />
          </div>
          <!-- /submission -->
        </template>
        <!-- /actions -->

        <!-- logs -->
        <template v-if="isProcessing">
          <div style="height: 70vh;">
            <!-- progress -->
            <div
              class="w-px h-1 bg-green-400 shadow transition-all duration-500 ease-out"
              :style="getProcessingStyle"
            />
            <!-- /progress -->
            <!-- summary -->
            <div class="grid grid-cols-5 gap-y-3 gap-x-4 py-2 border-b">
              <div class="col-span-2 px-4 h-12">
                <div>{{ hwA.processing }}</div>
                <small class="text-oLightGray">{{
                  $t('components.livemapManagement.list.bulk.processing')
                }}</small>
              </div>

              <div class="col-span-2 pl-2 h-12">
                <div class="text-left text-green-500">{{ hwA.succeeded }}</div>
                <small class="text-oLightGray">{{
                  $t('components.livemapManagement.list.bulk.succeeded')
                }}</small>
              </div>

              <div class="-ml-2.5 h-12">
                <div class="text-red-500">{{ hwA.failed }}</div>
                <small class="text-oLightGray">{{
                  $t('components.livemapManagement.list.bulk.failed')
                }}</small>
              </div>
            </div>
            <!-- /summary -->

            <section
              class="overflow-y-auto px-5 sb-farhan-light"
              style="height: 65vh"
            >
              <div
                v-for="process in hwALogs"
                :key="`log-item-${process.vehicle.id}`"
              >
                <div class="grid grid-cols-5 gap-y-3 gap-x-4 py-3 border-b">
                  <div class="text-xs">
                    {{ process.vehicle.qr_code }}
                  </div>

                  <div
                    class="flex col-span-3 justify-center items-start text-xs"
                  >
                    <template v-if="process.type === 'processing'">
                      <span class="text-gray-800">{{
                        $t('components.livemapManagement.list.bulk.processing')
                      }}</span>
                    </template>

                    <template v-else-if="process.type === 'succeeded'">
                      <span class="text-gray-400">{{
                        $t('components.livemapManagement.list.bulk.succeeded')
                      }}</span>
                    </template>

                    <template v-else-if="process.type === 'failed'">
                      <div
                        class="flex flex-col justify-center items-center mx-auto w-full"
                      >
                        <!-- <button
                          class="px-2 app-form-reset"
                          @click="
                            $store.commit('liveMap/toggleHwALog', {
                              vehicleId: process.vehicle.id,
                              isExpanded: !process.isExpanded,
                            })
                          "
                        >
                          <span class="text-oRed">
                            Failed
                          </span>

                          <span class="text-xs">
                            <i
                              class="ml-1.5  text-gray-400 fas fa-chevron-down"
                            />
                          </span>
                        </button>
   -->
                        <label
                          :for="process.vehicle.id"
                          class="text-center x-label text-oRed"
                        >
                          {{
                            $t('components.livemapManagement.list.bulk.failed')
                          }}
                          <i class="ml-1.5 text-gray-400 fas fa-chevron-down" />
                        </label>

                        <input
                          class="x-input"
                          type="checkbox"
                          checked
                          :id="process.vehicle.id"
                        />

                        <div class="x-hide">
                          <div class="py-2 px-1 text-sm font-bold text-black">
                            {{
                              $t(
                                'components.livemapManagement.list.bulk.errorCode'
                              )
                            }}: {{ process.logs.status || 'Unknown' }}
                          </div>

                          <pre
                            class="overflow-x-hidden font-mono font-normal text-white bg-black rounded-t-md"
                            style="width: 400px;"
                            >{{ process.logs }}</pre
                          >
                        </div>
                      </div>
                    </template>

                    <template v-else-if="process.type === 'timeout'">
                      <span class="text-red-500">{{
                        $t('components.livemapManagement.list.bulk.timeout')
                      }}</span>
                    </template>
                  </div>

                  <div>
                    <template v-if="process.type === 'processing'">
                      <i class="text-green-600 fas fa-spinner fa-pulse" />
                    </template>

                    <template v-else-if="process.type === 'succeeded'">
                      <i class="text-green-500 fas fa-check-circle" />
                    </template>

                    <template v-else-if="process.type === 'timeout'">
                      <i class="text-oRed fas fa-ban" />
                    </template>

                    <template v-else-if="process.type === 'failed'">
                      <i class="text-oRed fas fa-exclamation-circle" />
                    </template>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </template>
        <!-- /logs -->
      </div>
    </section>
    <!-- /bulk-actions -->
  </div>
</template>
